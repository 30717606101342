import React from 'react';
import sidebarLinks from './sidebarLinks';
import { Link } from 'react-router-dom';
import { Box, useColorMode } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

const SidebarMenu = () => {
  const location = useLocation();
  const { colorMode } = useColorMode();
  return (
    <Box
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      {sidebarLinks.map((link, index) => (
        <Link to={link.path} key={index}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              py: '8px',
              px: 3,
              borderRadius: 'md',
              fontWeight: '500',
              fontSize: 'sm',
              backgroundColor:
                location.pathname === link.path ? colorMode === 'light' ? 'gray.900' : 'white' : 'transparent',
              color: location.pathname === link.path ? colorMode === 'light' ? 'white' : 'gray.900' : 'inherit',
            }}
          >
            <link.icon size={18} />
            <Box ml={2}>{link.title}</Box>
          </Box>
        </Link>
      ))}
    </Box>
  );
};

export default SidebarMenu;
