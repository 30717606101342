import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const outline = defineStyle({
  boxShadow: 'sm',
  _focus: {
    boxShadow: '0 0 0 2px #17548f1f',
    borderColor: 'gray.500',
  },
  _invalid: {
    borderColor: 'red.500',
    boxShadow: '0 0 0 2px #fdebeb',
  },
});

const lg = defineStyle({
  fontSize: 'sm',
  px: 3,
  h: '40px',
  borderRadius: 'lg',
});

const md = defineStyle({
  fontSize: 'sm',
  px: 3,
  h: '36px',
  borderRadius: 'lg',
});

const sm = defineStyle({
  fontSize: 'sm',
  px: 3,
  h: '30px',
  borderRadius: 'md',
});

const sizes = {
  lg: definePartsStyle({ field: lg, addon: lg }),
  md: definePartsStyle({ field: md, addon: md }),
  sm: definePartsStyle({ field: sm, addon: sm }),
};

const variants = {
  outline: definePartsStyle({ field: outline }),
};

export default defineMultiStyleConfig({
  sizes,
  variants,
});
