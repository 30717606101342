import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle(props => ({
  list: {
    py: '4',
    borderRadius: 'lg',
    bg: props.colorMode === 'dark' ? 'gray.900' : 'white',
    boxShadow: 'sm',
    p: '5px',
    border: '1px solid',
    borderColor: props.colorMode === 'dark' ? 'gray.800' : 'gray.100',
  },
  item: {
    height: '30px',
    maxH: '30px',
    color: 'inherit',
    fontSize: 'sm',
    fontWeight: '500',
    borderRadius: 'md',
    px: '2',
    gap: '1',
    _hover: {
      bg: props.colorMode === 'dark' ? 'gray.800' : 'gray.50',
    },
    _focus: {
      bg: props.colorMode === 'dark' ? 'gray.800' : 'gray.50',
    },
  },
  command: {
    opacity: '0.8',
    fontFamily: 'mono',
    fontSize: 'sm',
    letterSpacing: 'tighter',
    pl: '4',
  },
  divider: {
    my: '1',
    borderColor: props.colorMode === 'dark' ? 'gray.800' : 'gray.100',
    width: 'calc(100% + 10px)',
    ml: '-5px',
  },
}));

const MenuTheme = defineMultiStyleConfig({ baseStyle });

export default MenuTheme;
