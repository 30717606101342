/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useFormik } from 'formik';
import Select from 'src/components/ui/select';
import * as Yup from 'yup';
import useToast from 'src/hooks/useToast';
import { Spinner, useColorModeValue } from '@chakra-ui/react';
import Input from 'src/components/ui/input';
import TextArea from 'src/components/ui/textArea';
import Switch from 'src/components/ui/switch';
import { useGetAlertQuery, useUpdateAlertMutation } from 'src/api';
import { Box, Button } from '@chakra-ui/react';

const websites = [
  { label: 'Tweeload', value: 'tweeload' },
  { label: 'Threadster', value: 'threadster' },
  { label: 'Viddit', value: 'viddit' },
  { label: 'Pin Video Saver', value: 'pinvideosaver' },
  { label: 'FSaver', value: 'fsaver' },
  { label: 'Dwitch', value: 'dwitch' },
];

const schema = Yup.object().shape({
  message: Yup.string(),
  type: Yup.string().required('Type is required'),
  enabled: Yup.boolean().required('Enabled is required'),
  button: Yup.object().shape({
    text: Yup.string(),
    link: Yup.string(),
  }),
});

const WebAlerts = () => {
  const toast = useToast();
  const [siteKey, setSiteKey] = React.useState('tweeload');
  const { data, isFetching, isError } = useGetAlertQuery(siteKey);
  const [updateAlert, { isLoading }] = useUpdateAlertMutation();

  const formik = useFormik({
    initialValues: {
      message: '',
      type: 'info',
      enabled: false,
      button: {
        text: '',
        link: '',
      },
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        await updateAlert({ siteKey, body: values }).unwrap();
        toast('Alert updated successfully', 'success');
      } catch (e: any) {
        console.log(e);
        toast('Something went wrong!', 'error');
      }
    },
  });
  const loaderBg = useColorModeValue('rgba(255, 255, 255, 0.5)', 'rgba(0, 0, 0, 0.5)')

  React.useEffect(() => {
    if (data) {
      formik.setValues({
        message: data.message,
        type: data.type || 'info',
        enabled: data.enabled || false,
        button: {
          text: data.button?.text || '',
          link: data.button?.link || '',
        },
      });
    }

    if (isError) {
      formik.resetForm();
    }
  }, [data, isError]);
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {isFetching && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bg: loaderBg,
            zIndex: 100,
            borderRadius: '10px',
          }}
        >
          <Spinner />
        </Box>
      )}
      <Box>
        <Select
          maxWidth="250px"
          matchWidth
          placeholder="Select Website"
          options={websites}
          onChange={(e) => setSiteKey(e as string)}
          value={siteKey}
        />
      </Box>
      <Box
        mt={4}
        sx={{
          border: '1px dashed',
          borderColor: useColorModeValue('gray.200', 'gray.600'),
          borderRadius: 'lg',
          p: 4,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { base: 'column', md: 'row' },
              gap: '20px',
            }}
          >
            <Box
              flex={1}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <TextArea
                label="Message"
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.errors.message}
                sx={{
                  minHeight: '208px',
                }}
              />
            </Box>
            <Box
              flex={1}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <Select
                label="Type"
                options={[
                  { label: 'Info', value: 'info' },
                  { label: 'Warning', value: 'warning' },
                  { label: 'Error', value: 'error' },
                ]}
                value={formik.values.type}
                onChange={(e) => formik.setFieldValue('type', e as string)}
                error={formik.errors.type}
              />
              <Input
                label="Button Text"
                name="button.text"
                value={formik.values.button.text}
                onChange={formik.handleChange}
                error={formik.errors.button?.text}
              />
              <Input
                label="Button Link"
                name="button.link"
                value={formik.values.button.link}
                onChange={formik.handleChange}
                error={formik.errors.button?.link}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '20px',
              mt: 4,
            }}
          >
            <Switch
              id="enabled"
              label="Enabled"
              name="enabled"
              isChecked={formik.values.enabled}
              onChange={formik.handleChange}
              error={formik.errors.enabled}
              maxW={100}
            />
            <Button type="submit" isLoading={isLoading} disabled={isLoading}>
              Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default WebAlerts;
