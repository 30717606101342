import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import InputTheme from './components/input';
import ButtonTheme from './components/button';
import MenuTheme from './components/menu';

const theme = extendTheme(
  {
    config: {
      useSystemColorMode: false,
    },
    direction: 'ltr',
    fonts: {
      heading: "'Noto Sans', sans-serif",
      body: "'Noto Sans', sans-serif",
    },
    styles: {
      global: (props: any) => ({
        'html': {
          scrollBehavior: 'smooth',
        },
        body: {
          overflowX: 'hidden',
          backgroundColor: props.colorMode === 'dark' ? 'gray.800' : 'white',
        },
        '.chakra-alert': {
          p: '7px 10px !important',
          borderRadius: 'lg !important',
        },
        '::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
          borderRadius: '24px',
        },
        '::-webkit-scrollbar-track': {
          width: '6px',
          height: '6px',
          borderRadius: '24px',
        },
        '::-webkit-scrollbar-thumb': {
          background: 'gray.600',
          borderRadius: '24px',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: 'gray.500',
        },
      }),
    },
    sizes: {
      container: {
        xl: '1200px',
      },
    },
    colors: {
      gray: {
        900: '#000000',
        800: '#0d0d0f',
        700: '#272727',
        600: '#4d4d4d',
        500: '#666666',
        400: '#999999',
        300: '#b3b3b3',
        200: '#dfdfdf',
        100: '#e6e6e6',
        50: '#f8f8f8',
      },
    },
    components: {
      Input: InputTheme,
      Button: ButtonTheme,
      Menu: MenuTheme,
    },
  },
  withDefaultColorScheme({ colorScheme: 'gray' }),
);

export default theme;
