import React from 'react';
import { Box, Container, useColorModeValue, IconButton, Button } from '@chakra-ui/react';
import { FiMenu } from "react-icons/fi";
import UserMenu from './userMenu';
import { SIDEBAR_WIDTH } from '../sidebar';
import { IoMoonOutline } from "react-icons/io5";
import { LuSun } from "react-icons/lu";
import { useColorMode } from '@chakra-ui/react';


export const HEADER_HEIGHT = 65;

function Header({
  setSidebarOpen,
}: {
  setSidebarOpen: () => void;
}) {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <Box
      sx={{
        height: `${HEADER_HEIGHT}px`,
        position: 'fixed',
        top: 0,
        left: { base: 0, md: `${SIDEBAR_WIDTH}px` },
        right: 0,
        zIndex: '998',
        bg: useColorModeValue('white', 'gray.900'),
        borderBottom: '1px solid',
        borderColor: useColorModeValue('gray.100', 'gray.800'),
      }}
    >
      <Container
        sx={{
          maxW: '100%',
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          gap: '2',
          px: 6,
        }}
      >
        <Box
          sx={{
            display: { base: 'flex', md: 'none' },
            alignItems: 'center',
            gap: '4',
          }}
        >
          <IconButton
            aria-label="Menu"
            icon={<FiMenu />}
            variant="unstyled"
            size="sm"
            fontSize="2xl"
            onClick={setSidebarOpen}
          />
          <Box sx={{ fontWeight: '700', fontSize: 'md' }}>Aculix Dashboard</Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            gap: '4',
            justifyContent: 'flex-end',
          }}
        >
          <Button size='sm' fontWeight='700' variant='gray' onClick={toggleColorMode}>
            {colorMode === "light" ? <IoMoonOutline /> : <LuSun />}
          </Button>
          <UserMenu />
        </Box>
      </Container>
    </Box>
  );
}

export default Header;
