import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    isAuthenticated: false,
    showSidebar: false,
    isLoading: true,
  },

  reducers: {
    loginReducer: (state, action) => {
      state.isAuthenticated = true;
    },
    logoutReducer: state => {
      state.isAuthenticated = false;
    },
    sidebarToggleReducer: (state, action) => {
      state.showSidebar = action.payload;
    },
    authLoadingReducer: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  loginReducer,
  logoutReducer,
  sidebarToggleReducer,
  authLoadingReducer,
} = appSlice.actions;
export default appSlice.reducer;
