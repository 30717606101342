import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import Api from 'src/api';
import appReducer from './slices/app.slice';

const store = configureStore({
  reducer: {
    app: appReducer,
    [Api.reducerPath]: Api.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(Api.middleware),
});

setupListeners(store.dispatch);

export default store;
