import React from 'react';
import {
  Switch as ChakraSwitch,
  FormControl,
  FormLabel,
  FormErrorMessage,
  type SwitchProps as ChakraSwitchProps,
} from '@chakra-ui/react';

interface SwitchProps extends ChakraSwitchProps {
  label?: string;
  error?: string | false | undefined;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  isRequired?: boolean;
  maxW?: number | string;
  id: string;
}

function Switch(props: SwitchProps) {
  const { label, error, isRequired, maxW, id, ...rest } = props;
  return (
    <FormControl
      display="flex"
      alignItems="center"
      maxW={maxW}
      isInvalid={!!error}
      isRequired={isRequired}
      gap={2}
    >
      <ChakraSwitch colorScheme='blue' id={id} {...rest} />
      {label && (
        <FormLabel
          htmlFor={id}
          sx={{
            fontWeight: '500',
            fontSize: 'sm',
            mb: 2,
            m: 0,
          }}
        >
          {label}
        </FormLabel>
      )}
      <FormErrorMessage
        sx={{
          fontSize: '12px',
          mt: 1,
        }}
      >
        {error}
      </FormErrorMessage>
    </FormControl>
  );
}

export default Switch;
