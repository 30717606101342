import React from 'react';
import {
  Input as ChakraInput,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  type InputProps as ChakraInputProps,
  useColorModeValue,
} from '@chakra-ui/react';

interface InputProps extends ChakraInputProps {
  label?: string;
  error?: string | false | undefined;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  isRequired?: boolean;
  maxW?: number | string;
}

function Input(props: InputProps) {
  const { label, error, leftElement, rightElement, isRequired, maxW, ...rest } =
    props;
  return (
    <FormControl maxW={maxW} isInvalid={!!error} isRequired={isRequired}>
      {label && (
        <FormLabel
          sx={{
            fontWeight: '500',
            fontSize: 'sm',
            mb: 2,
          }}
        >
          {label}
        </FormLabel>
      )}
      <InputGroup>
        {leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}
        <ChakraInput {...rest} sx={{
          bg: useColorModeValue('white', 'black'),
          borderColor: useColorModeValue('gray.200', 'gray.600'),
          color: useColorModeValue('black', 'white')
        }} />
        {rightElement && (
          <InputRightElement
            sx={{
              width: 'auto',
              right: '-1px',
              height: '100%',
            }}
          >
            {rightElement}
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage
        sx={{
          fontSize: '12px',
          mt: 1,
        }}
      >
        {error}
      </FormErrorMessage>
    </FormControl>
  );
}

export default Input;
