/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import Select from 'src/components/ui/select';
import { useGetOgWebsitesQuery, useAddOgWebsiteMutation } from 'src/api';
import OgImagesGrid from './ogImagesGrid';
import useToast from 'src/hooks/useToast';
import AddogWebsiteModal from './addOgWebsiteModal';

const OgImages = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [websiteId, setWebsiteId] = useState('');
  const { data, isLoading, refetch } = useGetOgWebsitesQuery();
  const [addOgWebsite, addResponse] = useAddOgWebsiteMutation();
  const toast = useToast();

  useEffect(() => {
    if (data && data.length > 0 && !websiteId) {
      setWebsiteId(data?.[0].id);
    }
  }, [data, websiteId]);

  useEffect(() => {
    if (addResponse.isSuccess) {
      onClose();
      setWebsiteId(addResponse.data?.id);
      refetch();
    } else if (addResponse.isError) {
      // @ts-ignore
      toast(addResponse.error?.data?.message || 'Something went wrong!', 'error');
    }
  }, [addResponse]);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Select
          maxWidth="250px"
          matchWidth
          isDisabled={isLoading}
          isLoading={isLoading}
          placeholder="Select Website"
          options={
            data?.map((item: any) => ({
              label: item.website,
              value: item.id,
            })) || []
          }
          onChange={e => setWebsiteId(e as string)}
          value={websiteId}
        />
        <Button minW={106} onClick={onOpen}>Add Website</Button>
      </Box>
      <OgImagesGrid id={websiteId} isEmpty={data?.length === 0} setId={setWebsiteId} refetch={refetch} />
      <AddogWebsiteModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={url => addOgWebsite({ url })}
        isLoading={addResponse.isLoading}
      />
    </Box>
  );
};

export default OgImages;
