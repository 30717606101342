import React, {useState} from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import Input from 'src/components/ui/input';

interface AddogWebsiteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (website: string) => void;
  isLoading: boolean;
}

function AddogWebsiteModal(props: AddogWebsiteModalProps) {
  const [website, setWebsite] = useState('');
  const { isOpen, onClose, onConfirm, isLoading } = props;
  const cancelRef = React.useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={() => !isLoading && onClose()}
      leastDestructiveRef={cancelRef}
      isCentered
    >
      <AlertDialogOverlay
        sx={{
          background: 'rgba(0, 0, 0, 0.75)',
        }}
      />
      <AlertDialogContent
        bg={useColorModeValue('white', 'gray.800')}
        borderRadius="lg"
        py={1}
      >
        <AlertDialogHeader fontSize="lg" fontWeight={600} pb={0}>
          Add Website
        </AlertDialogHeader>
        <AlertDialogBody
          sx={{
            fontSize: '15px',
            fontWeight: '400',
            py: 3,
            lineHeight: '1.4',
            color: useColorModeValue('gray.600', 'gray.400'),
          }}
        >
          <Input
            placeholder="Enter Website"
            value={website}
            onChange={e => setWebsite(e.target.value)}
          />
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            onClick={onClose}
            variant="outline"
            colorScheme="gray"
            mr={3}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onConfirm(website)}
            isLoading={isLoading}
            loadingText="Generating Images"
          >
            Generate
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default AddogWebsiteModal;
