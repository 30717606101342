import React from 'react';
import {
  Textarea as ChakraTextArea,
  FormControl,
  FormLabel,
  FormErrorMessage,
  type TextareaProps as ChakraTextAreaProps,
  useColorModeValue,
} from '@chakra-ui/react';

interface TextAreaProps extends ChakraTextAreaProps {
  label?: string;
  error?: string | false | undefined;
  isRequired?: boolean;
  maxW?: number | string;
}

function TextArea(props: TextAreaProps) {
  const { label, error, isRequired, maxW, sx, ...rest } = props;
  return (
    <FormControl maxW={maxW} isInvalid={!!error} isRequired={isRequired}>
      {label && (
        <FormLabel
          sx={{
            fontWeight: '500',
            fontSize: 'sm',
            mb: 2,
          }}
        >
          {label}
        </FormLabel>
      )}
      <ChakraTextArea
        sx={{
          boxShadow: 'sm',
          bg: useColorModeValue('white', 'black'),
          borderColor: useColorModeValue('gray.200', 'gray.600'),
          color: useColorModeValue('black', 'white'),
          _focus: {
            boxShadow: '0 0 0 2px #17548f1f',
            borderColor: 'gray.500',
          },
          _invalid: {
            borderColor: 'red.500',
            boxShadow: '0 0 0 2px #fdebeb',
          },
          borderRadius: 'lg',
          ...sx,
        }}
        {...rest}
      />
      <FormErrorMessage
        sx={{
          fontSize: '12px',
          mt: 1,
        }}
      >
        {error}
      </FormErrorMessage>
    </FormControl>
  );
}

export default TextArea;
