import { LuLayoutDashboard } from "react-icons/lu";
import { PiImages } from "react-icons/pi";
import { MdOutlineDns } from "react-icons/md";
import { HiOutlineBellAlert } from "react-icons/hi2";

const sidebarLinks = [
  {
    title: 'Dashboard',
    path: '/',
    icon: LuLayoutDashboard,
  },
  {
    title: 'Og Images',
    path: '/og-images',
    icon: PiImages,
  },
  {
    title: 'DNS Servers',
    path: '/dns-servers',
    icon: MdOutlineDns,
  },
  {
    title: 'Alert Messages',
    path: '/web-alerts',
    icon: HiOutlineBellAlert,
  }
];

export default sidebarLinks;