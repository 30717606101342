import React from 'react';
import { Box, Container, useColorModeValue } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import Header, { HEADER_HEIGHT } from 'src/components/header';
import Sidebar, { SIDEBAR_WIDTH } from 'src/components/sidebar';

function MainLayout() {
  const [isSidebarOpen, setSidebarOpen] = React.useState(false);
  return (
    <Box
      sx={{
        minHeight: '100vh',
        pt: `${HEADER_HEIGHT}px`,
        pl: { base: 0, md: `${SIDEBAR_WIDTH}px` },
        bg: useColorModeValue('white', 'gray.800')
      }}
    >
      <Header setSidebarOpen={() => setSidebarOpen(!isSidebarOpen)} />
      <Sidebar hideSidebar={() => setSidebarOpen(false)} isSidebarOpen={isSidebarOpen} />
      <Box>
        <Container maxW="container.xl" px={6} py={6}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}

export default MainLayout;
