/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Box,
  useColorMode,
  Spinner,
  useClipboard,
  Button,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { LuCopy } from 'react-icons/lu';
import { FaCheck } from 'react-icons/fa6';
import {
  useGetOgImagesMutation,
  useUpdateOgWebsiteMutation,
  useDeleteOgWebsiteMutation,
  useUpdateOgWebsiteImageMutation,
} from 'src/api';
import { MdArrowOutward } from 'react-icons/md';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { TbRefresh } from 'react-icons/tb';
import { RiDeleteBin5Line } from 'react-icons/ri';
import useToast from 'src/hooks/useToast';
import {
  atomOneLight,
  atomOneDark,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';

const OgImageBox = ({ item, websiteId }: { item: any; websiteId: string }) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [updateOgImage, updateResponse] = useUpdateOgWebsiteImageMutation();
  const { hasCopied, onCopy } = useClipboard(`<meta
      property="og:image"
      content="${item?.imageUrl}" />`);

  useEffect(() => {
    if (updateResponse.isError) {
      toast('Failed to refresh image', 'error');
    }

    if (updateResponse.isSuccess) {
      toast('Image refreshed successfully', 'success');
    }
  }, [updateResponse.isError, updateResponse.isSuccess]);
  return (
    <Box
      sx={{
        width: {
          base: '100%',
          lg: 'calc(50% - 10px)',
        },
        mb: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        p: '15px',
        border: '1px solid',
        boxShadow: 'sm',
        borderColor: colorMode === 'light' ? 'gray.200' : 'gray.700',
        borderRadius: 'lg',
      }}
    >
      <Box
        sx={{
          fontSize: 'sm',
          display: 'inline-flex',
          alignItems: 'center',
          color: colorMode === 'light' ? 'gray.500' : 'gray.500',
          gap: '5px',
        }}
      >
        <Box as="span" fontWeight={500}>
          URL:
        </Box>
        <Box
          color={colorMode === 'light' ? 'black' : 'white'}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
          }}
          as="a"
          href={item.url}
          target="_blank"
        >
          <Box
            sx={{
              maxWidth: {
                base: '150px',
                lg: '300px',
              },
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {item.url}
          </Box>
          <Box
            as={MdArrowOutward}
            sx={{
              ml: '5px',
              fontSize: 'sm',
            }}
          />
        </Box>
        <Box ml="auto">
          <IconButton
            aria-label="Refresh Image"
            size="sm"
            variant="gray"
            minW="30px"
            icon={<TbRefresh />}
            onClick={() =>
              updateOgImage({
                websiteId,
                imageId: item._id,
              })
            }
            isLoading={updateResponse.isLoading}
          />
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            fontSize: 'sm',
            fontWeight: 600,
            color: colorMode === 'light' ? 'gray.500' : 'gray.500',
          }}
        >
          Snippet
        </Box>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <IconButton
            size="sm"
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              minWidth: '30px',
            }}
            aria-label="Copy"
            onClick={onCopy}
            variant="outline"
            icon={hasCopied ? <FaCheck /> : <LuCopy />}
          />
          <Box
            sx={{
              fontSize: 'sm',
              borderRadius: 'md',
              mt: '10px',
            }}
            as={SyntaxHighlighter}
            language="html"
            style={colorMode === 'light' ? atomOneLight : atomOneDark}
          >
            {`<!-- Put in your <head> tag -->
<meta
  property="og:image"
  content="${item?.imageUrl}" />
`}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const OgImagesGrid = ({
  id,
  isEmpty,
  setId,
  refetch,
}: {
  id?: string;
  isEmpty: boolean;
  setId: any;
  refetch: any;
}) => {
  const ref = React.useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const [getOgImages, response] = useGetOgImagesMutation();
  const [updateOgWebsite, updateResponse] = useUpdateOgWebsiteMutation();
  const [deleteOgWebsite, deleteResponse] = useDeleteOgWebsiteMutation();
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getOgImages(id);
    }
  }, [getOgImages, id]);

  useEffect(() => {
    if (
      (response?.isError || response?.isSuccess || isEmpty) &&
      !response.isLoading
    ) {
      setIsLoading(false);
    }
  }, [response?.isError, response?.isSuccess, isEmpty, response.isLoading]);

  useEffect(() => {
    if (deleteResponse.isSuccess) {
      toast('Website deleted successfully', 'success');
      setId('');
      refetch();
    }

    if (deleteResponse.isError) {
      toast('Failed to delete website', 'error');
    }
  }, [deleteResponse.isSuccess, deleteResponse.isError]);

  useEffect(() => {
    if (updateResponse.isSuccess) {
      toast('Website refreshed successfully', 'success');
      getOgImages(id);
    }

    if (updateResponse.isError) {
      toast('Failed to refresh website', 'error');
    }
  }, [updateResponse.isSuccess, updateResponse.isError]);

  return (
    <>
      {isLoading || isEmpty ? (
        <Box
          sx={{
            border: '1px dashed',
            borderColor: colorMode === 'light' ? 'gray.200' : 'gray.700',
            borderRadius: 'lg',
            minHeight: 'calc(100vh - 165px)',
            mt: '15px',
          }}
        >
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 'calc(100vh - 173px)',
              gap: '20px',
            }}
          >
            {isLoading ? (
              <Spinner size="xl" />
            ) : (
              <>
                <Box
                  sx={{
                    fontWeight: '600',
                    fontSize: 'md',
                  }}
                >
                  You have no websites added.
                </Box>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            border: '1px dashed',
            borderColor: colorMode === 'light' ? 'gray.200' : 'gray.700',
            minHeight: 'calc(100vh - 165px)',
            borderRadius: 'lg',
            mt: '15px',
          }}
        >
          <Box
            sx={{
              bg: colorMode === 'light' ? 'gray.100' : 'gray.800',
              p: '15px',
              borderTopLeftRadius: 'lg',
              borderTopRightRadius: 'lg',
              width: 'calc(100% + 2px)',
              ml: '-1px',
              mt: '-1px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: { base: 'flex-start', md: 'center' },
              flexDirection: { base: 'column', md: 'row' },
              gap: '10px',
            }}
          >
            <Box
              sx={{
                fontSize: 'sm',
                fontWeight: 600,
              }}
            >
              {response?.data?.website}
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <Button
                variant="outline"
                leftIcon={<TbRefresh />}
                size="sm"
                borderColor={colorMode === 'light' ? 'gray.300' : 'gray.700'}
                onClick={() => updateOgWebsite(id)}
                isLoading={updateResponse.isLoading}
                loadingText="Refreshing"
              >
                Refresh
              </Button>
              <Button
                variant="outline"
                leftIcon={<RiDeleteBin5Line />}
                colorScheme="red"
                size="sm"
                onClick={() => setIsModalOpen(true)}
                isLoading={deleteResponse.isLoading}
                loadingText="Deleting"
              >
                Delete
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              p: '15px',
            }}
          >
            {response?.data?.ogImages?.map((item: any) => (
              <OgImageBox key={item._id} item={item} websiteId={id || ''} />
            ))}
          </Box>
        </Box>
      )}
      <AlertDialog
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isCentered
        leastDestructiveRef={ref}
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          bg={colorMode === 'light' ? 'white' : 'gray.800'}
          borderRadius="lg"
          p={2}
        >
          <AlertDialogHeader pb={0} fontSize="lg" fontWeight={600}>
            Delete Website
          </AlertDialogHeader>
          <AlertDialogBody py={1}>
            Are you sure you want to delete this website?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              onClick={() => {
                setIsModalOpen(false);
              }}
              variant="outline"
              colorScheme="gray"
              mr={3}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                deleteOgWebsite(id);
                setIsModalOpen(false);
              }}
              colorScheme="red"
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default OgImagesGrid;
