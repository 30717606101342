import React from 'react';
import {
  Box,
  Button,
  useColorModeValue,
  Heading,
  Text,
} from '@chakra-ui/react';
import Input from 'src/components/ui/input';
import { useLoginMutation } from '../api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useToast from 'src/hooks/useToast';
import { useDispatch } from 'react-redux';
import { loginReducer } from 'src/redux/slices/app.slice';

const loginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

function LoginPage() {
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async values => {
      try {
        const response = await login(values).unwrap();
        toast('Login successful', 'success');
        dispatch(loginReducer(response));
        navigate('/');
      } catch (error: any) {
        toast(error.data.message, 'error');
      }
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        padding: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: useColorModeValue('gray.50', 'gray.900'),
      }}
    >
      <Box
        sx={{
          border: '1px solid',
          borderColor: useColorModeValue('gray.100', 'gray.700'),
          borderRadius: '2xl',
          padding: '30px',
          width: '100%',
          maxWidth: '460px',
          backgroundColor: useColorModeValue('white', 'gray.800'),
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '13px',
            alignItems: 'flex-start',
          }}
          as="form"
          onSubmit={formik.handleSubmit}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '2',
              mb: '2',
            }}
          >
            <Heading
              as="h5"
              sx={{
                fontSize: 'md',
                fontWeight: '700',
              }}
            >
              Welcome back!
            </Heading>
          </Box>
          <Text
            sx={{
              fontSize: 'sm',
              mt: '-4',
              mb: '2',
              color: useColorModeValue('gray.600', 'gray.400'),
            }}
          >
            Enter your username and password to sign in
          </Text>
          <Input
            size="lg"
            label="Username"
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.username && formik.errors.username}
          />
          <Input
            size="lg"
            label="Password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && formik.errors.password}
          />
          <Button
            size="lg"
            sx={{
              width: '100%',
              mt: '4',
            }}
            type="submit"
            isLoading={isLoading}
            loadingText="Sign in"
          >
            Sign in
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default LoginPage;
