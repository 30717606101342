/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  useDisclosure,
  useColorMode,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import { countries } from 'countries-list';
import Input from 'src/components/ui/input';
import Select from 'src/components/ui/select';
import {
  useGetDnsServersQuery,
  useAddDnsServerMutation,
  useUpdateDnsServerMutation,
  useDeleteDnsServerMutation,
  useGetDnsServerMutation,
  useImportDnsServersMutation,
} from 'src/api';
import useToast from 'src/hooks/useToast';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const dnsServerSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  ip: Yup.string().required('Server IP is required'),
  longitude: Yup.string().required('Longitude is required'),
  latitude: Yup.string().required('Latitude is required'),
  country: Yup.string().required('Country is required'),
  countryName: Yup.string().required('Country Name is required'),
  status: Yup.string().required('Status is required'),
});

function AddDnsServerModal({
  isOpen,
  onClose,
  refetch,
  id,
}: {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  id?: string | null;
}) {
  const toast = useToast();
  const [addDnsServerMutation, { isLoading }] = useAddDnsServerMutation();
  const [updateDnsServerMutation, updateResponse] =
    useUpdateDnsServerMutation();
  const [getDnsServerMutation, getResponse] = useGetDnsServerMutation();

  const formik = useFormik({
    initialValues: {
      name: '',
      ip: '',
      longitude: '',
      latitude: '',
      country: '',
      countryName: '',
      status: 'active',
    },
    validationSchema: dnsServerSchema,
    onSubmit: async (values) => {
      try {
        if (id) {
          await updateDnsServerMutation({ id, body: values }).unwrap();
          refetch();
          onClose();
          toast('DNS Server updated successfully', 'success');
          return;
        }
        await addDnsServerMutation(values).unwrap();
        refetch();
        onClose();
        toast('DNS Server added successfully', 'success');
      } catch (error: any) {
        if (id) {
          toast(error.data.message, 'error');
          return;
        }
        toast(error.data.message, 'error');
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  useEffect(() => {
    if (id) {
      getDnsServerMutation(id);
    }
  }, [id]);

  useEffect(() => {
    if (getResponse.data) {
      formik.setValues({
        name: getResponse.data.name,
        ip: getResponse.data.ip,
        longitude: getResponse.data.longitude,
        latitude: getResponse.data.latitude,
        country: getResponse.data.country,
        countryName: getResponse.data.countryName,
        status: getResponse.data.status,
      });
    }
  }, [getResponse.data]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent borderRadius="xl" bg={useColorModeValue('white', 'gray.800')} border='1px solid' borderColor={useColorModeValue('gray.400', 'gray.700')}>
        <ModalHeader
          sx={{
            fontSize: 'md',
            fontWeight: 'bold',
          }}
        >
          {id ? 'Update DNS Server' : 'Add DNS Server'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'column',
            }}
          >
            <Input
              label="Name"
              value={formik.values.name}
              error={formik.touched.name && formik.errors.name}
              name="name"
              onChange={formik.handleChange}
            />
            <Input
              label="Server IP"
              value={formik.values.ip}
              error={formik.touched.ip && formik.errors.ip}
              name="ip"
              onChange={formik.handleChange}
            />
            <Input
              label="Longitude"
              value={formik.values.longitude}
              error={formik.touched.longitude && formik.errors.longitude}
              name="longitude"
              onChange={formik.handleChange}
            />
            <Input
              label="Latitude"
              value={formik.values.latitude}
              error={formik.touched.latitude && formik.errors.latitude}
              name="latitude"
              onChange={formik.handleChange}
            />
            <Select
              matchWidth
              label="Country"
              placeholder="Select Country"
              value={formik.values.country}
              error={formik.touched.country && formik.errors.country}
              options={Object.keys(countries).map((code) => ({
                // @ts-ignore
                label: countries[code].name,
                value: code,
              }))}
              onChange={(value) => {
                formik.setFieldValue('country', value);
                // @ts-ignore
                formik.setFieldValue('countryName', countries[value].name);
              }}
            />
            <Select
              matchWidth
              label="Status"
              value={formik.values.status}
              error={formik.touched.status && formik.errors.status}
              options={[
                { label: 'Active', value: 'active' },
                { label: 'Inactive', value: 'inactive' },
              ]}
              onChange={(value) => {
                formik.setFieldValue('status', value);
              }}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="gray" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            ml={3}
            minW={70}
            onClick={() => formik.handleSubmit()}
            isLoading={
              isLoading || updateResponse.isLoading || getResponse.isLoading
            }
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const DnsServers = () => {
  const ref = React.useRef(null);
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, refetch } = useGetDnsServersQuery();
  const toast = useToast();
  const [deleteId, setDeleteId] = React.useState<string | null>(null);
  const [updateId, setUpdateId] = React.useState<string | null>(null);
  const [deleteDnsServerMutation, deleteResponse] =
    useDeleteDnsServerMutation();
  const [importDnsServersMutation, importResponse] = useImportDnsServersMutation();

  const handleExport = async () => {
    try {
      const blob = new Blob([JSON.stringify(data)], {
        type: 'application/json',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'dns-servers.json';
      a.click();
      toast('DNS Servers exported successfully', 'success');
    } catch (error: any) {
      toast(error?.message || 'Something went wrong', 'error');
    }
  };

  const handleImport = async (e: any) => {
    try {
      const file = e.target.files[0];
      if (!file) return;
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = JSON.parse(event.target?.result as string);
        console.log(data);
        await importDnsServersMutation({ dnsServers: data }).unwrap();
        refetch();
        toast('DNS Servers imported successfully', 'success');
      };
      reader.readAsText(file);
    } catch (error: any) {
      toast(error?.data?.message || 'Something went wrong', 'error');
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: { base: 'flex-start', md: 'center' },
          flexDirection: { base: 'column', md: 'row' },
          justifyContent: 'space-between',
          gap: '14px',
        }}
      >
        <Box
          sx={{
            fontSize: 'md',
            fontWeight: 'bold',
          }}
        >
          Dns Servers
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <Button
            isLoading={importResponse.isLoading}
            variant="gray"
            sx={{
              fontSize: 'sm',
              fontWeight: 'bold',
              p: '16px',
              position: 'relative',
            }}
          >
            Import
            <Box
              as="input"
              cursor="pointer"
              type="file"
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              zIndex={4}
              opacity={0}
              onChange={handleImport}
            />
          </Button>
          <Button
            variant="gray"
            colorScheme="blue"
            size="sm"
            sx={{
              fontSize: 'sm',
              fontWeight: 'bold',
              p: '16px',
            }}
            onClick={handleExport}
          >
            Export
          </Button>
          <Button
            colorScheme="blue"
            size="sm"
            sx={{
              fontSize: 'sm',
              fontWeight: 'bold',
              p: '16px',
            }}
            onClick={onOpen}
          >
            Add DNS Server
          </Button>
        </Box>
      </Box>
      <Box mt={4} sx={{
        border: '1px solid',
        borderColor: useColorModeValue('gray.100', 'gray.700'),
        borderRadius: 'md',
        overflow: 'auto',
      }}>
        <Table
          sx={{
            th: {
              fontSize: 'xs',
              fontWeight: 'bold',
              p: '16px',
              color: useColorModeValue('black', 'white'),
            },
            td: {
              fontSize: 'sm',
              p: '7px 16px',
            },
          }}
        >
          <Thead>
            <Tr>
              <Th>Country</Th>
              <Th>Name</Th>
              <Th>Server IP</Th>
              <Th>Status</Th>
              <Th width={150}>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((dnsServer: any) => (
              <Tr key={dnsServer.id}>
                <Td>{dnsServer.countryName}</Td>
                <Td>{dnsServer.name}</Td>
                <Td>{dnsServer.ip}</Td>
                <Td>
                  <Box
                    sx={{
                      color: dnsServer.status === 'active' ? 'green' : 'red',
                      fontWeight: 'bold',
                      fontSize: 'xs',
                      borderRadius: 'md',
                      p: '2px 5px',
                      border: '1px solid',
                      borderColor:
                        dnsServer.status === 'active' ? 'green' : 'red',
                        maxWidth: 'max-content',
                    }}
                  >
                    {dnsServer.status === 'active' ? 'Active' : 'Inactive'}
                  </Box>
                </Td>
                <Td>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      size="sm"
                      colorScheme="blue"
                      sx={{
                        fontSize: 'xs',
                        fontWeight: 'bold',
                        p: '16px',
                      }}
                      variant="gray"
                      onClick={() => {
                        setUpdateId(dnsServer.id);
                        onOpen();
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      size="sm"
                      sx={{
                        fontSize: 'xs',
                        fontWeight: 'bold',
                        p: '16px',
                        backgroundColor:
                          colorMode === 'light' ? 'red.500' : 'red.400',
                        _hover: {
                          backgroundColor:
                            colorMode === 'light' ? 'red.600' : 'red.500',
                        },
                      }}
                      onClick={() => {
                        setDeleteId(dnsServer.id);
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Td>
              </Tr>
            ))}
            {isLoading && (
              <Tr>
                <Td colSpan={5} textAlign="center">
                  <Box
                    sx={{
                      minHeight: 'calc(100vh - 250px)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Spinner size="lg" />
                  </Box>
                </Td>
              </Tr>
            )}
            {data?.length === 0 && !isLoading && (
              <Tr>
                <Td colSpan={5} textAlign="center">
                  <Box
                    sx={{
                      minHeight: 'calc(100vh - 250px)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    No data found
                  </Box>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <AddDnsServerModal
        id={updateId}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setUpdateId(null);
        }}
        refetch={refetch}
      />
      <AlertDialog
        isOpen={!!deleteId}
        onClose={() => setDeleteId(null)}
        isCentered
        leastDestructiveRef={ref}
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          bg={colorMode === 'light' ? 'white' : 'gray.800'}
          borderRadius="lg"
          p={2}
        >
          <AlertDialogHeader pb={0} fontSize="lg" fontWeight={600}>
            Delete DNS Server
          </AlertDialogHeader>
          <AlertDialogBody py={1}>
            Are you sure you want to delete this DNS Server?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              onClick={() => {
                setDeleteId(null);
              }}
              variant="outline"
              colorScheme="gray"
              mr={3}
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await deleteDnsServerMutation(deleteId).unwrap();
                refetch();
                toast('DNS Server deleted successfully', 'success');
                setDeleteId(null);
              }}
              colorScheme="red"
              isLoading={deleteResponse.isLoading}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DnsServers;
