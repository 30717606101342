import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';

import theme from 'src/theme';
import store from 'src/redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AuthProvider from './providers/authProvider';
import LoginPage from './views/login';
import Dashboard from './views/dashboard';
import OgImages from './views/ogImages';
import DnsServers from './views/dnsServers';
import MainLayout from './layouts/mainLayout';
import WebAlerts from './views/webAlerts';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route element={<AuthProvider />}>
              <Route path="/login" element={<LoginPage />} />
              <Route element={<MainLayout />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dns-servers" element={<DnsServers />} />
                <Route path="/og-images" element={<OgImages />} />
                <Route path="/web-alerts" element={<WebAlerts />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </Provider>
    </ChakraProvider>
  );
}

export default App;
