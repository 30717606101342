import React, { useEffect } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authLoadingReducer, loginReducer } from 'src/redux/slices/app.slice';
import { useLazyAuthenticateQuery } from 'src/api';
import AuthOverlay from './authOverlay';

const publicRoutes = [
  '/login',
];

function AuthProvider() {
  const [authenticate, { data, isError, isSuccess }] = useLazyAuthenticateQuery();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading } = useSelector((state: any) => state?.app ?? {});

  useEffect(() => {
    if (!isAuthenticated) {
      authenticate();
    }
  }, [dispatch, authenticate, isAuthenticated]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(loginReducer(data));
    }

    if (isError || isSuccess) {
      dispatch(authLoadingReducer(false));
    }
  }, [dispatch, data, isSuccess, isError]);

  if (isLoading) {
    return <AuthOverlay />;
  }
  if (!isAuthenticated && !publicRoutes.includes(location.pathname)) {
    return <Navigate to="/login" replace />;
  }
  if (isAuthenticated && publicRoutes.includes(location.pathname)) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}

export default AuthProvider;
