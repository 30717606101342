import { useToast as useChakraToast } from '@chakra-ui/react';

const useToast = () => {
  const toast = useChakraToast();

  const showToast = (
    message: string,
    status: 'success' | 'error' | 'warning' | 'info',
  ) => {
    toast({
      title: message,
      status,
      duration: 3000,
      isClosable: true,
      position: 'top',
      containerStyle: {
        fontSize: 'sm',
      },
    });
  };

  return showToast;
};

export default useToast;
