import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { HEADER_HEIGHT } from '../header';
import SidebarMenu from './menu';

export const SIDEBAR_WIDTH = 250;

const Sidebar = ({
  isSidebarOpen,
  hideSidebar,
}: {
  isSidebarOpen: boolean;
  hideSidebar: () => void;
}) => {
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          bg: 'rgba(0,0,0,0.6)',
          zIndex: '998',
          transition: 'opacity 0.3s',
          opacity: isSidebarOpen ? '1' : '0',
          visibility: isSidebarOpen ? 'visible' : 'hidden',
        }}
        onClick={hideSidebar}
      />
      <Box
        sx={{
          width: `${SIDEBAR_WIDTH}px`,
          backgroundColor: useColorModeValue('white', 'gray.900'),
          borderRight: '1px solid',
          borderColor: useColorModeValue('gray.100', 'gray.800'),
          position: 'fixed',
          top: '0',
          left: '0',
          bottom: '0',
          overflowY: 'auto',
          transition: 'transform 0.3s',
          transform: {
            base: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)',
            md: 'translateX(0)',
          },
          zIndex: '999',
        }}
      >
        <Box
          sx={{
            height: `${HEADER_HEIGHT}px`,
            display: 'flex',
            alignItems: 'center',
            p: 4,
            fontWeight: '700',
            fontSize: 'md',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.100', 'gray.800'),
          }}
        >
          Aculix Dashboard
        </Box>
        <SidebarMenu />
      </Box>
    </>
  );
};

export default Sidebar;
