import React from 'react';
import { Box, useColorModeValue, Spinner } from '@chakra-ui/react';

function AuthOverlay() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: useColorModeValue('white', 'gray.900'),
      }}
    >
      <Spinner
        size="lg"
        color={useColorModeValue('gray.500', 'gray.400')}
      />
    </Box>
  );
}

export default AuthOverlay;
