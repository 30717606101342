/* eslint-disable react/prop-types */
import React from 'react';
import { RxCaretSort } from 'react-icons/rx';
import { FaCheck } from 'react-icons/fa6';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Button,
  Text,
  useColorMode,
  type PlacementWithLogical,
  Divider,
  Box,
  Checkbox,
  Spacer,
  SystemStyleObject,
  Spinner,
} from '@chakra-ui/react';

interface SelectProps {
  value: string | string[];
  placeholder?: string;
  options: Array<{ value: string; label: string; heading?: string }>;
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: string | string[]) => void;
  label?: string;
  helperText?: string;
  error?: string | null | undefined | boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  valueComponent?: () => React.ReactNode;
  matchWidth?: boolean;
  placement?: PlacementWithLogical;
  optionsWidth?: string;
  maxWidth?: string;
  minWidth?: string;
  valueStyle?: SystemStyleObject;
  isLoading?: boolean;
}

function Select({
  value,
  placeholder,
  options,
  onChange,
  label,
  helperText,
  error,
  isRequired,
  isDisabled,
  isMulti,
  valueComponent,
  matchWidth,
  placement,
  optionsWidth,
  maxWidth,
  minWidth,
  valueStyle = {},
  isLoading,
}: SelectProps) {
  const { colorMode } = useColorMode();

  return (
    <FormControl
      isDisabled={isDisabled}
      isRequired={isRequired}
      isInvalid={!!error}
      sx={{
        width: '100%',
        maxWidth: maxWidth || '100%',
        minWidth: minWidth || '50px',
      }}
    >
      {label && (
        <FormLabel
          sx={{
            fontWeight: '500',
            fontSize: 'sm',
            mb: 2,
          }}
        >
          {label}
        </FormLabel>
      )}
      <Popover
        matchWidth={matchWidth}
        isLazy
        placement={placement || 'bottom-start'}
        offset={[0, 3]}
      >
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              {valueComponent ? (
                valueComponent()
              ) : (
                <Button
                  rightIcon={isLoading ? <Spinner size="sm" /> : <RxCaretSort size={16} />}
                  variant="outline"
                  width="100%"
                  colorScheme="gray"
                  fontWeight="500"
                  bg={colorMode === 'dark' ? 'black' : 'white'}
                  sx={{
                    justifyContent: 'space-between',
                    textAlign: 'left',
                    borderRadius: 'lg',
                    px: '3',
                    lineHeight: '1',
                    '.chakra-button__icon': {
                      mr: '-1',
                    },
                    ...valueStyle,
                  }}
                  justifyContent="space-between"
                  isDisabled={isDisabled}
                >
                  {options.find(option => option.value === value)?.label ||
                    placeholder}
                </Button>
              )}
            </PopoverTrigger>
            <PopoverContent
              sx={{
                width: matchWidth ? '100%' : optionsWidth || '200px',
                bg: colorMode === 'dark' ? 'gray.900' : 'white',
                boxShadow: 'sm',
                border: '1px solid',
                borderColor: colorMode === 'dark' ? 'gray.800' : 'gray.100',
                borderRadius: 'lg',
                p: '5px',
                maxHeight: '300px',
                overflowY: 'auto',
              }}
            >
              <PopoverBody
                sx={{
                  width: '100%',
                  p: 0,
                  gap: '2px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {options.map(option => (
                  <React.Fragment key={option.value}>
                    {option?.heading ? (
                      <Box>
                        <Text
                          sx={{
                            fontSize: 'sm',
                            fontWeight: '500',
                            p: '5px',
                          }}
                        >
                          {option.heading}
                        </Text>
                        <Divider
                          sx={{
                            my: '2px',
                            width: 'calc(100% + 10px)',
                            ml: '-5px',
                          }}
                        />
                      </Box>
                    ) : (
                      <Button
                        variant="unstyled"
                        key={option.value}
                        width="100%"
                        justifyContent="space-between"
                        onClick={() => {
                          if (isMulti) {
                            if (
                              value?.includes(option.value || '') &&
                              Array.isArray(value)
                            ) {
                              onChange?.(
                                value?.filter(
                                  (val: string) => val !== option.value,
                                ),
                              );
                            } else if (Array.isArray(value)) {
                              onChange?.([...value, option.value]);
                            }
                          } else {
                            onChange?.(option.value);
                          }

                          if (!isMulti) {
                            onClose();
                          }
                        }}
                        sx={{
                          width: '100%',
                          textAlign: 'left',
                          p: '5px 7px',
                          height: 'auto',
                          minHeight: 'auto',
                          borderRadius: 'md',
                          fontWeight: '500',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          gap: '7px',
                          _hover: {
                            bg: colorMode === 'dark' ? 'gray.800' : 'gray.50',
                          },
                        }}
                      >
                        {isMulti && (
                          <Checkbox
                            isChecked={value?.includes(option.value || '')}
                            onChange={e => {
                              if (e.target.checked && Array.isArray(value)) {
                                onChange?.([...value, option.value]);
                              } else if (Array.isArray(value)) {
                                onChange?.(
                                  value?.filter(
                                    (val: string) => val !== option.value,
                                  ),
                                );
                              }
                            }}
                          />
                        )}
                        {option.label}
                        <Spacer />
                        {!isMulti && option.value === value && <FaCheck />}
                      </Button>
                    )}
                  </React.Fragment>
                ))}
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
      <FormErrorMessage
        sx={{
          fontSize: '12px',
          mt: 1,
        }}
      >
        {error}
      </FormErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default Select;
