import React from 'react';
import { logoutReducer } from 'src/redux/slices/app.slice';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@chakra-ui/react';
import { useLogoutMutation } from 'src/api';

function UserMenu() {
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    try {
      await logout().unwrap();
      dispatch(logoutReducer());
    } catch (error) {
      dispatch(logoutReducer());
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Button size='sm' fontWeight='700' variant='gray' onClick={handleLogout}>Logout</Button>
    </Box>
  );
}

export default UserMenu;
