import { defineStyleConfig } from '@chakra-ui/react';

const ButtonTheme = defineStyleConfig({
  variants: {
    solid: props => ({
      bg: props.colorMode === 'light' ? 'black' : 'white',
      color: props.colorMode === 'light' ? 'gray.100' : 'gray.800',
      _disabled: {
        bg: props.colorMode === 'light' ? 'gray.800' : 'gray.100',
        opacity: 1,
        hover: {
          bg: props.colorMode === 'light' ? 'gray.800' : 'gray.100',
          opacity: 1,
        },
      },
      _hover: {
        bg: props.colorMode === 'light' ? 'gray.800' : 'gray.100',
        _disabled: {
          bg: props.colorMode === 'light' ? 'gray.800' : 'gray.100',
          opacity: 1,
        },
      },
      _active: {
        bg: props.colorMode === 'light' ? 'gray.800' : 'gray.600',
      },
    }),
    gray: props => ({
      bg: props.colorMode === 'light' ? 'white' : 'black',
      color: props.colorMode === 'light' ? 'gray.800' : 'gray.200',
      border: '1px solid',
      boxShadow: 'sm',
      borderColor: props.colorMode === 'light' ? 'gray.200' : 'gray.700',
      _hover: {
        bg: props.colorMode === 'light' ? 'gray.100' : 'gray.700',
      },
      _active: {
        bg: props.colorMode === 'light' ? 'gray.200' : 'gray.600',
      },
    }),
  },
  sizes: {
    lg: {
      h: '40px',
      fontSize: 'sm',
      px: 4,
      fontWeight: '500',
      borderRadius: 'lg',
    },
    md: {
      h: '36px',
      fontSize: '13px',
      px: 3,
      fontWeight: '600',
      borderRadius: 'lg',
    },
    sm: {
      h: '30px',
      fontSize: 'xs',
      px: 2,
      fontWeight: '500',
      minW: 6,
      borderRadius: 'md',
    },
  },
});

export default ButtonTheme;
